<template>
    <form
        class="space-y-6"
        @submit.prevent="submit"
    >
        <h3 class="subtitle">
            Créer une réservation pour {{ baby.first_name }} {{ baby.last_name }}
        </h3>
        <UiSelect
            v-model="type"
            label="Type"
            name="type"
            required
            :options="[
                { value: 3, label: 'Réservation demi journée' },
                { value: 4, label: 'Réservation journée complète' }
            ]"
        />
        <div class="grid grid-cols-2 gap-6">
            <UiInput
                v-model="start_at"
                label="Arrive à"
                type="time"
                name="start_at"
                required
                placeholder="Arrive à"
                step="900"
            />
            <UiInput
                v-model="left_at"
                type="time"
                name="left_at"
                label="Part à"
                required
                placeholder="Part à"
                step="900"
            />
        </div>

        <UiTextarea
            v-model="note"
            label="Remarques"
            name="note"
            placeholder="Écrivez ici"
        />

        <UiToggle
            v-model="protected_from_regeneration"
            name="protected_from_regeneration"
        >
            Empêcher cette réservation d'être re-générée
        </UiToggle>

        <div class="text-right">
            <button
                type="submit"
                class="btn btn-primary"
                :class="{ loading: isLoading }"
            >
                Sauvegarder
            </button>
        </div>
    </form>
</template>

<script>
import dayjs from 'dayjs'
import { dateRangeMixin, loadingMixin } from 'abcreche-ui'
import AttendanceRepository from '~/repositories/Creche/AttendanceRepository.js'

export default {
    mixins: [loadingMixin, dateRangeMixin],

    props: {
        baby: {
            type: Object,
            required: true
        },
        registration: {
            type: Object,
            required: true
        },
        date: {
            type: [String, Object, Date],
            default: () => dayjs()
        }
    },

    emits: ['created'],

    data () {
        return {
            day: dayjs(this.date).format('YYYY-MM-DD'),
            note: null,
            type: null,
            start_at: '09:00',
            left_at: '18:00',
            protected_from_regeneration: true
        }
    },

    computed: {
        quarterHours () {
            return this.generateDateRange(
                dayjs().hour(6).minute(0),
                dayjs().hour(19).minute(59),
                15,
                'minutes',
                'HH:mm'
            )
        }
    },

    methods: {
        submit () {
            this.load()

            const datetimeLeft = this.day + ' ' + this.left_at
            const datetimeStart = this.day + ' ' + this.start_at

            AttendanceRepository.create(this.registration.uuid, {
                baby_uuid: this.baby.uuid,
                type: this.type,
                note: this.note,
                datetime: this.day,
                left_at: datetimeLeft,
                start_at: datetimeStart,
                protected_from_regeneration: this.protected_from_regeneration
            })
                .then(() => {
                    this.$emit('created')
                    this.done('La réservation a été correctement créée !')
                    this.emitter.emit('calendar:refresh')
                    this.$modal.closeAll()
                })
                .catch(error => this.doneWithErrors(error.response.data))
        },

        changeHours (h) {
            this.start_at = h[0]
            this.left_at = h[1]
        }
    }
}
</script>
